// Colors
$debugColor: #ff0000; // Used for debug text
$debugColorPlacement: #ff000000; // Used for asset placement

$lightColor: #ffffff;
$primaryColor: #ffffff00;
$bgColor: #666666;

$demoBgColor: #000000; // BG color

// AdminButton color
$adminButtonBGColor: #00000000;
$adminButtonTextColor: #ff0000;

// Feedback color
$feedbackOverlayBgColor: #00000099;
$feedbackBgColor: #666666;

// Size
$fontSizeLarge: 18px;
$fontSizeMed: 16px;
$fontSize: 14px;
$fontSizeSmall: 12px;
