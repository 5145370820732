html,
body {
  position: fixed;
  width: 100vw;
  height: 100vh;

  background-color: $demoBgColor;
  padding: 0;
  margin: 0;

  -webkit-tap-highlight-color: transparent; // Removes the grey bg
  font-family: sans-serif;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  #root {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  h1,
  h2,
  p {
    font-weight: normal;
  }
  h1 {
    //text-transform: uppercase;
  }
}

// Blink animation, override can happening in css
@keyframes button-blinking {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes led-blinking {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.blinkButton {
  animation-name: button-blinking;
  animation-duration: 1.32s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
.blink {
  animation-name: led-blinking;
  animation-duration: 1.32s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

// Remove blue outline in chrome
*:focus {
  outline: 0 !important;
}
